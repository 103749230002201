<template>
  <div class="propiedades-imagenes">
    <!-- <b-row>
      <b-col cols="12" xl="12">
        <app-filtro
          :nombre="nombre"
          :incluirInactivos="incluirInactivos"
          @filtrar="filtrar">
        </app-filtro>  
      </b-col>      
    </b-row> -->

    <b-row>
      <b-col cols="12" xl="12">  
        <transition name="slide">
        <b-card header="Listado de Imagenes" >
          <b-table hover
              responsive="sm" 
              class="registro"
              :busy="tableBusy"
              :items="imagenes" 
              :fields="itemFields"
              :current-page="paginaActual"
              :per-page="registrosPorPagina">
              <!-- @row-clicked="consultar"> -->
            <div slot="table-busy" class="text-center">
              <b-spinner class="table-spinner" label="Spinning" />
            </div>

            <template slot="imagen" slot-scope="data">
              <b-img :src="data.item.url" fluid></b-img>
            </template>

            <template slot="main" slot-scope="data">
              {{ data.item.main ? 'Si' : 'No' }}
            </template>

            <template slot="opciones" slot-scope="data">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template slot="button-content">
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click.stop="setImagenMain(data.item)">
                  <i class="fa fa-edit"></i>Establecer como Destacada</b-dropdown-item>
                <b-dropdown-item @click.stop="borrarImagen(data.item)">
                  <i class="fa fa-edit"></i>Borrar Imagen</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" 
              :total-rows="totalRegistros" 
              :per-page="registrosPorPagina" 
              v-model="paginaActual" prev-text="Prev." next-text="Prox."/>
          </nav>
        </b-card>
        </transition>
      </b-col>
    </b-row>

    <div class="fixed-btn">
      <div class="list-dropdown-btn">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template slot="button-content">
            <i class="fa fa-ellipsis-v fa-2x" aria-hidden="true"></i>
          </template>
          
          <b-dropdown-item @click.stop="agregarImagen">
            <i class="fa fa-plus"></i>Agregar Imagen</b-dropdown-item>

          <b-dropdown-item @click.stop="goBack">
            <i class="fa fa-undo"></i>Volver</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <b-modal ref="imagenModal"
        title="Imagen"
        modal-class="modalABM"
        header-bg-variant= "light" 
        hide-footer
        lazy>
      <app-imagen
        :propiedadId="propiedadId"
        @close="closeImagen">
      </app-imagen>  
    </b-modal>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>

    <app-modal-confirm
      :visible="confirmModalVisible"
      :mensaje="confirmMsj"
      :okTitle="confirmOkTitle"
      :cancelTitle="confirmCancelTitle"
      @confirmOk="confirmOk"
      @confirmCancel="confirmCancel">
    </app-modal-confirm>
  </div>
</template>

<script>
import Imagen from './Imagen';
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});


export default {
  name: 'PropiedadesImagenes',
  components: {
    appImagen: Imagen,
  },
  data () {
    return {
      propiedadId: '',
      itemFields: [
        { key: 'imagen' },
        // { key: 'url' },
        { 
          key: 'main',
          label: 'Destacada'

        },
        { 
          key: 'opciones',
          label: '',
          _classes: 'text-center'
        },
      ],

      tableBusy: false,
      selected: null,
      
    }
  },
  computed: {
    ...mapFields([
      'imagenes',
      'paginaActual',
      'registrosPorPagina',
      'totalRegistros',
      'totalPaginas'
    ]),
  },
  created() {
    if (this.$route.params){
      if (this.$route.params.id){
        this.propiedadId = this.$route.params.id;
      }
    }

    this.getItems();
  },
  methods: {
    getItems() {
      this.tableBusy = true;
      this.$store.dispatch('propiedad/getImagenesPropiedad', this.propiedadId)
        .then(() => this.tableBusy = false)
        .catch(error => this.showErrorMsj(error));
    },

    // rowClicked (item, index) {
    //   this.$router.push({ name: 'Propiedad', 
    //                 params: { id: item.id }});
    // },
    agregarImagen () {
      this.$refs.imagenModal.show();
    },
    setImagenMain (item) {
      let params = {
        propiedadId: this.propiedadId,
        imagenId: item._id
      }
      
      this.showSpinner();
      this.$store.dispatch('propiedadImagen/setMain', params)
        .then(() => this.getItems)
        .catch(error => this.showErrorMsj(error))
        .finally(() => this.hideSpinner());
    },
    closeImagen(imagenActualizada) {
      this.$refs.imagenModal.hide();
      if ((/true/i).test(imagenActualizada)) {
        this.getItems();
      }
    },
    borrarImagen (item) {
      let params = {
        propiedadId: this.propiedadId,
        imagenId: item._id
      }
      
      this.showSpinner();
      this.$store.dispatch('propiedad/deleteImagen', params)
        .then(() => this.getItems)
        .catch(error => this.showErrorMsj(error))
        .finally(() => this.hideSpinner());
    },
    goBack() {
      this.$router.push({ name: 'Propiedades' });
    },
  },
  // watch: {
  //   'paginaActual' : function(newVal, oldVal) {
  //     if ( newVal != 0 && oldVal != 0) {
  //       this.getItems() 
  //     }
  //   },    
  // }    
}
</script>

<style scoped>
  .propiedades-imagenes table img {
    max-height: 200px;
    width: auto;
  }
</style>