<template>
  <div>
    <b-row>
      <b-col xl="12">
        <b-card no-header>
          <b-alert
            class="alert"
            :show="dismissCountDown"
            dismissible
            fade
            :variant="alertVariant"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged">
            <p>{{ alertMsj }}</p>
          </b-alert>

          <b-form>
            <!-- <b-form-file 
              v-model="file" 
              class="mt-3" 
              accept=".jpg, .png, .jpeg" 
              placeholder="Seleccione la imagen o arrastrela hasta aquí">
            </b-form-file> -->

            <b-form-group
              description="Imagenes (jpg, png o jpeg) de hasta 300KB">
              <b-form-file 
                v-model="files" 
                class="mt-3" 
                accept=".jpg, .png, .jpeg" 
                placeholder="Seleccione una o más imágenes o arrastrelas hasta aquí"
                multiple>
              </b-form-file>
            </b-form-group>

            <!-- <b-form-group
              id="form-group-main"
              label-for="main"
              :label-cols="labelCols">
              <b-form-checkbox 
                  id="main"
                  v-model="main"
                  value= true
                  unchecked-value= false>
                  Imagen destacada
              </b-form-checkbox>
            </b-form-group> -->

          </b-form>
          <div slot="footer">
            <b-button size="sm" variant="primary" class="footer-button"
              @click.prevent="save">Agregar</b-button>
            <b-button size="sm" variant="danger" class="footer-button"
              @click.prevent="closeModal">Cancelar</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PropiedadesImagenABM',
  props: {
    propiedadId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      files: [],
      // file: '',
      main: false,

      upload: [],

      labelCols: 0,
      alertCountDown: 10, 
      dismissCountDown: 0,
      alertVariant: 'danger',
      alertMsj: ''
    }
  },
  created() {
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(alertMsj) {
      this.alertMsj = alertMsj;
      this.alertVariant = "light"
      this.dismissCountDown = this.alertCountDown;
    },    
    showErrorAlert(error) {
      this.alertMsj = error;
      this.alertVariant = "danger"
      this.dismissCountDown = this.alertCountDown;
    },    
    save() {
      let save = false;

      if (this.files) {
        if (this.files.length != 0) {
          save = true
        }
      }
      if (save) {
        this.showSpinner();

        let isMain = true;

        this.files.forEach(file => {
          let params = {
            propiedadId: this.propiedadId,
            file: file,
            main: isMain
            // main: this.main
          }

          /* Marcamos la primera como principal */
          isMain = false;

          this.upload.push(file);

          this.$store.dispatch('propiedad/addImagen', params)
            .then( () => this.fileUploaded(file))
            .catch( error => {
              this.hideSpinner();
              this.showErrorAlert (error);
            });
        });
      } else {
        this.showErrorAlert ('Seleccione la o las imágenes que desea agregar.');
      }
    },
    fileUploaded(file) {
      console.log('Uploaded');

      let index = this.upload.findIndex(uploaded => uploaded.name == file.name);

      if (index != -1) {
        this.upload.splice(index, 1);

        if (this.upload.length == 0) {
          this.hideSpinner();
          this.close(true);
        }
      }
    },
    closeModal() {
      this.close(false);
    },
    close(imagenActualizada) {
      this.$emit('close', imagenActualizada);
    }
  },
}
</script>

<style scoped>
  #form-group-main {
    margin-top: 15px;
    padding-left: 25px;
  }
</style>